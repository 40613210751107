/* ==========================================================================
   Hero
   ========================================================================== */

/* Hero Image for Front Page */
.hero-image-container {
  background-color: $Gray;
  position: relative;
  &.without-bkgd-image {
    background-color: none;
  }
}
img.image-hero-front-page {
  display: block;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  min-height: 460px;
  padding-left: 100%; /* Equal to width of new image */
  @media (max-width: $BreakPointTablet) {
    padding-left: 0;
    min-height: inherit;
  }
}

.Mia-logo {
  position: absolute;
  background-image: url(/src/images/mia-logo.svg);
  background-repeat: no-repeat;
  bottom: -1px;
  left: -1px;
  width: 100%;
  height: auto;
  min-height: 135px;
  z-index: 1;
  padding: 7%;
  @media (max-width: $BreakPointTabletSm) {
    background-size: 150px;
    min-height: inherit;
    padding: 29px;
  }
  @media (max-width: $BreakPointMobile) {
    display: none;
  }
}
.hero-text-block {
  position: relative;
  height: auto;
  min-height: 460px;
  @media (max-width: $BreakPointTablet) {
    min-height: inherit;
  }
}
.hero-email-sign-up {
  margin: 0 0 1em 0;
}

/* Image hero for L2 Pages and beyond */
.image-hero {
  object-fit: cover;
}
img.image-hero {
  display: block;
  width: 100%;
  height: auto;
}
img.masthead {
  display: block;
  margin-bottom: 2rem;
}
