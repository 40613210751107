/* ==========================================================================
   Fonts
   ========================================================================== */

@font-face {
  font-family: 'iconi_mia';
  font-path: '/src/fonts/iconi_mia';
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Mia Light";
  font-path: 'https://mia-grotesk.s3.amazonaws.com/MiaGrotesk-Light';
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mia Regular";
  font-path: 'https://mia-grotesk.s3.amazonaws.com/MiaGrotesk-Regular';
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mia Bold";
  font-path: 'https://mia-grotesk.s3.amazonaws.com/MiaGrotesk-Bold';
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mia Black";
  font-path: 'https://mia-grotesk.s3.amazonaws.com/MiaGrotesk-Black';
  font-weight: normal;
  font-style: normal;
}

$Mia-Light: "Mia Light", "Helvetica Neue", Helvetica, san-serif;
$Mia-Regular: "Mia Regular", "Helvetica Neue", Helvetica, san-serif;
$Mia-Bold: "Mia Bold", "Helvetica Neue", Helvetica, san-serif;
$Mia-Black: "Mia Black", "Helvetica Neue", Helvetica, san-serif;

/* -------------------------------------------------------------------------- *
 * Social Icon Fonts
 * -------------------------------------------------------------------------- */

@font-face {
  font-family: 'mfg_labs_arrows';
  font-path: '/src/fonts/mfg_labs_arrows';
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?62827046#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "iconi_mia";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-miavimeo:before {
  content: '\e800';
} /* '' */
.icon-miaflickr:before {
  content: '\e801';
} /* '' */
.icon-miafacebook:before {
  content: '\e802';
} /* '' */
.icon-miainstagram:before {
  content: '\e803';
} /* '' */
.icon-miasearch:before {
  content: '\e804';
} /* '' */
.icon-miatumblr:before {
  content: '\e805';
} /* '' */
.icon-miatwitter:before {
  content: '\e806';
} /* '' */
.icon-miayoutube:before {
  content: '\e807';
} /* '' */
.icon-miaprint:before {
  content: '\e808';
} /* '' */
.icon-miacontact:before {
  content: '\e809';
} /* '' */
