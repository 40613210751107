/* ==========================================================================
   Header Styles
   ========================================================================== */
.screen-reader-text,
.sr-only {
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  width: 1px;
}
.header-logo {
  position: relative;
  z-index: 20;
  display: block;
  width: 415px;
  height: 36.5px;
  /* background-image: url(../../src/images/mia-wordmark.svg);
  Better to use img */
  background-repeat: no-repeat;
  text-indent: -9999px;
  text-align: left;
  @media (max-width: $BreakPointDesktop) {
    height: 30px;
    width: 350px;
  }
  @media (max-width: 350px) {
    width: 100%;
  }
}

h1.logo-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

h1.logo-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

header nav ul {
  display: inline-block;
  padding-left: 0;
  /* padding-right: 1.5rem; */
  margin-bottom: 0;

  & li {
    display: inline-block;
    font-size: .8rem;
    vertical-align: middle;
    margin-right: 1.6em;
    position: relative;
    & a {
      color: $Black;
      border-bottom: 1px solid rgba($Black, 0);
      &:hover {
        border-bottom: 1px solid $DarkGray;
      }
    }
    & button {
      background: none;
      color: $Black;
      padding: 0;
      margin: 0;
      font-family: $Mia-Light;
      border-bottom: 1px solid rgba($Black, 0);
      font-size: .8rem;
      margin-top: .6em;
      margin-bottom: .5em;
      @media (max-width: $BreakPointDesktop) {
        margin-top: 0;
        margin-bottom: 0;
      }
      &:hover {
        border-bottom: 1px solid $DarkGray;
      }
    }
  }
  &.secondary-links {
    & li:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: $BreakPointDesktop) {
    margin-top: 0;
    margin-right: 1em;
    vertical-align: top;
    &.secondary-links {
      & li {
        display: inline-block;
        & a {
          display: inline-block;
        }
      }
    }
    & li {
      /* display: block; */
      margin-top: .5em;
      &.search {
        margin-top: 0;
      }
    }
  }
}

.dropdown-list {
  position: relative;
  display: inline-block;
  & a {
    /*     margin-top: .6em;
    margin-bottom: .5em; */
    display: block;
    @media (max-width: $BreakPointDesktop) {
      margin-top: 0;
    }
  }
}

.dropdown-container {
  border: $BorderBlack;
  background-color: rgba($White, 0.9);
  display: none;
  position: absolute;
  min-width: 400px;
  z-index: 1;
  padding: .5em .75em;
  &.text-align-right {
    text-align: right;
    right: 0;
    /* border-right: $BorderBlack; */
    & li {
      margin-right: 0.5em;
    }
  }
}
.dropdown-content {
  padding-right: 0;
  display: none;
  margin-right: 1.5em;
  margin-bottom: 0.25em;
  vertical-align: top;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  & li {
    margin-right: 0;
    margin-bottom: 0;
    display: block;
  }
  &.text-align-right {
    text-align: right;
    right: 0;
    margin-right: 0;
    /* border-right: $BorderBlack; */
    & li {
      padding-right: 0;
    }
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: .5em 0;
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  font-size: 0.8rem;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  color: $Black;
  border-bottom: $BorderBlack;
}

/* Show the dropdown menu on hover */
.dropdown-list:hover .dropdown-container {
  display: block;
  @media (max-width: $BreakPointDesktop) {
    display: none;
  }
}
.dropdown-list:hover .dropdown-content {
  display: inline-block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.primary-links {
  font-family: $Mia-Black;
}

.secondary-links {
  /* Allows Search to be positioned absolute */
  /* margin-left: 40px; */
}

.nav {
  display: inherit;
  margin-top: 4px; /* Aligns the nav with Brand */
  @media (max-width: $BreakPointDesktop) {
    text-align: right;
    padding-right: .5em;
    margin-top: 0;
  }
  @media (max-width: $BreakPointTabletSm) {
    text-align: left;
  }
}

#nav-items {
  display: inline-block;
  @media (max-width: $BreakPointDesktop) {
    display: none;
    transition: $Transition;
    height: 0;
    will-change: opacity;
    &.open-nav {
      display: inline-block;
      opacity: 1;
      height: 100%;
      animation: fade-in 0.5s forwards;
    }
    &.close {
      animation: fade-out 0.5s forwards;
      height: 0;
    }
  }
}

#nav-icon {
  display: none;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  top: 4px;
  transform: rotate(0deg);
  transition: $Transition;
  cursor: pointer;
  @media (max-width: $BreakPointDesktop) {
    display: block;
    &.open {
      top: 7px;
      /* For Screen Readers */
      &:before {
        content: 'close';
        overflow: hidden;
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        padding: 0;
        position: absolute;
        width: 1px;
        left: -5px;
        bottom: 5px;
      }
    }
  }
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 22px;
  background: $Black;
  opacity: 1;
  right: 0;
  transform: rotate(0deg);
  transition: $Transition;
}

#nav-icon span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 8px;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 16px;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 12px;
}

/* Dropdown Click */
.dropdown-click {
  position: absolute;
  display: none;
  width: 600px;
  z-index: 10000;
  & ul {
    & li {
      display: block;
      & a {
        background: rgba($Black, 0.95);
        color: $White;
        padding: 1em;
        display: block;
        font-size: 0.85em;
      }
    }
  }
}

/* Modal technology */
.modal-bkgd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba($White, 0.75);
  display: none;
  animation: fade-in 0.5s forwards;
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 100%;
  background-color: rgba($White, 0.9);
  z-index: 1000;
  padding: 2em;
  height: 400px;
  max-height: 100%;
  display: none;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  animation: fade-in 0.5s forwards;

  & h2 {
    text-transform: capitalize;
  }

  & ul.modal-content {
    font-size: 0.8rem;
    & li {
      &:after {
        content: '|';
        display: inline;
        margin-left: 1.5em;
      }
      &:last-of-type {
        &:after {
          content: '';
        }
      }
      & a {
        display: inherit;
      }
    }
  }

  & button.close-button {
    position: absolute;
    top: 1.5em;
    right: 1em;
    transition: inherit;
    display: block;
    padding: 0.75em;
    &:hover {
      border-bottom: none;
    }

    &:before,
    &:after {
      display: block;
      position: absolute;
      height: 4px;
      width: 24px;
      background: $Black;
      opacity: 1;
      right: 10px;
      content: '';
    }

    &:before {
      transform: rotate(45deg);
      top: 3px;
    }

    &:after {
      transform: rotate(-45deg);
      top: 3px;
    }
  }
}

.open {
  display: block;
}
